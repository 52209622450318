<div class="min-vh-100">
  <app-menu class="fixed-top flex-shrink-0 header" id="header"></app-menu>
  <app-toast></app-toast>
  <div class="min-vh-100 mainpage" style="padding-top: 58px">
    <div [@routeAnimations]="getRouteAnimationData()" *ngIf="langLoanded">
      <router-outlet></router-outlet>
    </div>
  </div>

  <app-footer class="flex-shrink-0 bg-dark text-bg-dark"></app-footer>
</div>
