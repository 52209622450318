import { MODE, URLS } from './constants';
import { getVersionedUrl, loadModule } from './utils';
import { RevolutPaymentsVersionLoader } from './versionLoader';
var loadedUpsellInstance = null;
export function RevolutUpsellLoader(token, mode, locale) {
  if (mode === void 0) {
    mode = RevolutUpsellLoader.mode;
  }
  if (loadedUpsellInstance) {
    var instance = loadedUpsellInstance({
      publicToken: token,
      locale: locale
    });
    return Promise.resolve(instance);
  }
  return RevolutPaymentsVersionLoader(mode).then(function (version) {
    return loadRevolutUpsell(version, token, mode, locale);
  });
}
function loadRevolutUpsell(version, token, mode, locale) {
  return loadModule({
    src: getVersionedUrl(URLS[mode].upsell, version),
    id: 'revolut-upsell',
    name: 'RevolutUpsell'
  }).then(function (scriptElement) {
    if (typeof RevolutUpsell === 'function') {
      loadedUpsellInstance = RevolutUpsell;
      delete window.RevolutUpsell;
      return loadedUpsellInstance({
        publicToken: token,
        locale: locale
      });
    } else {
      document.head.removeChild(scriptElement);
      throw new Error("'RevolutUpsell' failed to load: RevolutUpsell is not a function");
    }
  });
}
RevolutUpsellLoader.mode = MODE.PRODUCTION;