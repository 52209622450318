import { Component, OnInit, ViewChild } from "@angular/core";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { FormGroup, FormsModule, NgModel } from "@angular/forms";
import { ApiService } from "../../../Core/Services/Api/api.service";
import { CustomerType } from "../../../Core/models/Master";
import { CommonModule } from "@angular/common";
import { UserService } from "../../../Core/Services/User/user.service";
import { Router } from "@angular/router";
import { PasswordStrengthDirective } from "../../../Core/Directives/passwordStrength-validator.directive";
import { MatchDirective } from "../../../Core/Directives/match-validator.directive";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { BillingDataComponent } from "./billing-data/billing-data.component";
import { Register } from "../../../Core/models/Register";
import { UserDataComponent } from "./user-data/user-data.component";
import { ActivationComponent } from "./activation/activation.component";
import { TranslateModule } from "@ngx-translate/core";
import { ToastService } from "../../../Core/Toast/toast.service";

@Component({
  selector: "app-register",
  host: {
    class: "flex-shrink-0 flex-grow-1",
  },
  standalone: true,
  imports: [
    MatSelectModule,
    MatFormFieldModule,
    FormsModule,
    CommonModule,
    PasswordStrengthDirective,
    MatchDirective,
    FontAwesomeModule,
    BillingDataComponent,
    UserDataComponent,
    ActivationComponent,
    TranslateModule,
  ],
  templateUrl: "./register.component.html",
  styleUrl: "./register.component.scss",
})
export class RegisterComponent {
  @ViewChild(UserDataComponent) user?: UserDataComponent;
  google: string = "";
  facebook: any;
  constructor(
    private apiService: ApiService,
    private userService: UserService,
    private router: Router,
    private toastService: ToastService
  ) {
    const navigation = this.router.getCurrentNavigation();
    if (navigation && navigation.extras && navigation.extras.state) {
      this.google = navigation.extras.state["google"];
      this.facebook = navigation.extras.state["facebook"];
    }
  }

  register() {
    if (this.user) {
      this.apiService
        .UserRegister(this.user?.userForm, this.google, this.facebook)
        .subscribe({
          next: (response) => {
            if (response.result) {
              if (this.user?.userForm.controls.email.value) {
                this.userService
                  .login(
                    this.user.userForm.controls.email.value,
                    this.user?.userForm.controls.password.value
                  )
                  .subscribe({
                    next: (value) => {
                      if (value.result && value.data) {
                        localStorage.setItem("apitoken", value.data);
                        this.userService.getUserData();
                        if (!this.google) {
                          this.router.navigate(["register", "activation"]);
                        } else {
                          this.router.navigate([""]);
                        }
                      }
                    },
                  });
              }
            } else {
              this.toastService.showAlert(response.error);
            }
          },
        });
    }
  }
}
