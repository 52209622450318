<form [formGroup]="code" *ngIf="!success">
  <div class="container mt-5 mb-5">
    <p class="text-center">
      {{ "activation.before" | translate }}
    </p>
    <h2 class="text-center m-2 p-2 fs-1 fw-bold">
      {{ "activation.title" | translate }}
    </h2>
    <div class="row justify-content-center">
      <div class="col col-1">
        <input
          maxlength="1"
          id="c_1"
          (input)="next_input('c_1', 'c_2')"
          (paste)="onPaste($event)"
          class="col-1 form-control fs-3 fw-bold text-center border"
          formControlName="c_1"
          [ngClass]="
            code.controls['c_1'].value
              ? 'border-success'
              : code.controls['c_1'].touched
              ? 'border-danger'
              : 'border-primary'
          "
        />
      </div>
      <div class="col col-1">
        <input
          maxlength="1"
          id="c_2"
          (input)="next_input('c_2', 'c_3')"
          (paste)="onPaste($event)"
          class="col-1 form-control fs-3 fw-bold text-center border"
          formControlName="c_2"
          [ngClass]="
            code.controls['c_2'].value
              ? 'border-success'
              : code.controls['c_2'].touched
              ? 'border-danger'
              : 'border-primary'
          "
        />
      </div>
      <div class="col col-1">
        <input
          maxlength="1"
          id="c_3"
          (input)="next_input('c_3', 'c_4')"
          (paste)="onPaste($event)"
          class="col-1 form-control fs-3 fw-bold text-center border"
          formControlName="c_3"
          [ngClass]="
            code.controls['c_3'].value
              ? 'border-success'
              : code.controls['c_3'].touched
              ? 'border-danger'
              : 'border-primary'
          "
        />
      </div>
      <div class="col col-1">
        <input
          maxlength="1"
          id="c_4"
          (input)="next_input('c_4', 'c_5')"
          (paste)="onPaste($event)"
          class="col-1 form-control fs-3 fw-bold text-center border"
          formControlName="c_4"
          [ngClass]="
            code.controls['c_4'].value
              ? 'border-success'
              : code.controls['c_4'].touched
              ? 'border-danger'
              : 'border-primary'
          "
        />
      </div>
      <div class="col col-1">
        <input
          maxlength="1"
          id="c_5"
          (input)="next_input('c_5', 'c_6')"
          (paste)="onPaste($event)"
          class="col-1 form-control fs-3 fw-bold text-center border"
          formControlName="c_5"
          [ngClass]="
            code.controls['c_5'].value
              ? 'border-success'
              : code.controls['c_5'].touched
              ? 'border-danger'
              : 'border-primary'
          "
        />
      </div>
      <div class="col col-1">
        <input
          maxlength="1"
          id="c_6"
          (paste)="onPaste($event)"
          (input)="next_input('', '')"
          class="col-1 form-control fs-3 fw-bold text-center border"
          formControlName="c_6"
          [ngClass]="
            code.controls['c_6'].value
              ? 'border-success'
              : code.controls['c_6'].touched
              ? 'border-danger'
              : 'border-primary'
          "
        />
      </div>
    </div>
    <p class="text-center mt-5">
      {{ "activation.after1" | translate }}
      <a (click)="resendClicked()" class="resend">{{
        "activation.click" | translate
      }}</a>
      {{ "activation.after2" | translate }}
    </p>
  </div>
</form>
<div class="container mt-5 mb-5" *ngIf="success">
  <h2 class="text-center m-2 p-2 fs-1 fw-bold">
    {{ "activation.success" | translate }}
  </h2>
  <div
    class="progress"
    role="progressbar"
    aria-label="Basic example"
    aria-valuenow="0"
    aria-valuemin="0"
    aria-valuemax="100"
  >
    <div class="progress-bar progress-bar-striped progress-bar-animated"></div>
  </div>
</div>
