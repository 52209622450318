<div class="container p-5 text-center">
  <h3>{{ "xoa_login.enter_password" | translate }}</h3>
  <div class="input-group mt-3">
    <span class="input-group-text">{{ "xoa_login.password" | translate }}</span>
    <input
      type="password"
      class="form-control invalid"
      [ngClass]="{ invalid: invalid_password == true }"
      autofocus
      [(ngModel)]="password"
    />
  </div>
  <p class="alert alert-danger mt-1" *ngIf="invalid_password">
    {{ "xoa_login.invalid_password" | translate }}
  </p>
  <button
    class="btn btn-outline-success form-control mt-4"
    (click)="onPasswordSend()"
  >
    {{ "xoa_login.submit" | translate }}
  </button>
</div>
