<div class="container p-4">
  <button class="btn btn-outline-default close-btn" (click)="close()">X</button>
  <p>
    {{ "vps.proxy.info.1" | translate }}
  </p>
  <p>
    {{ "vps.proxy.info.2" | translate }}
  </p>
  <p>
    {{ "vps.proxy.info.3" | translate }}
  </p>
  <p>
    {{ "vps.proxy.info.4" | translate }}
  </p>
</div>
