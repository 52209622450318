<div class="container pb-5">
  <div class="card mt-5" (click)="onBlogClick(blog)" *ngFor="let blog of blogs">
    <div class="card-header">
      <h1>{{ blog.title }}</h1>
    </div>
    <div class="card-body">
      <p [innerHTML]="sanitizer.bypassSecurityTrustHtml(blog.prolog)"></p>
    </div>
    <div class="card-footer">
      <p>{{ blog.author }} : {{ blog.publication }}</p>
    </div>
  </div>
</div>
