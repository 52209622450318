import { Component, NgModuleRef } from "@angular/core";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "app-vps-info",
  standalone: true,
  imports: [TranslateModule],
  templateUrl: "./vps-info.component.html",
  styleUrl: "./vps-info.component.scss",
})
export class VpsInfoComponent {
  constructor(private activeModal: NgbModal) {}
  close() {
    this.activeModal.dismissAll();
  }
}
