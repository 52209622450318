import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from "@angular/forms";
import { ApiService } from "../../../../Core/Services/Api/api.service";
import { Router } from "@angular/router";
import { UserService } from "../../../../Core/Services/User/user.service";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ToastService } from "../../../../Core/Toast/toast.service";

@Component({
  selector: "CLM-activation",
  host: {
    class: "flex-shrink-0 flex-grow-1",
  },
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, TranslateModule],
  templateUrl: "./activation.component.html",
  styleUrl: "./activation.component.scss",
})
export class ActivationComponent {
  constructor(
    private apiService: ApiService,
    private router: Router,
    private userService: UserService,
    private toastService: ToastService,
    private translate: TranslateService
  ) {}

  success: boolean = false;
  public code: FormGroup = new FormGroup({
    c_1: new FormControl(""),
    c_2: new FormControl(""),
    c_3: new FormControl(""),
    c_4: new FormControl(""),
    c_5: new FormControl(""),
    c_6: new FormControl(""),
  });

  next_input(current: string, next: string) {
    if (this.allCodeDone()) {
      this.onCodeSend();
      return;
    }
    if (current == "") {
      return;
    }
    if (this.code.controls[current].value.length >= 1) {
      document.getElementById(next)?.focus();
    }
  }

  onPaste(event: ClipboardEvent) {
    event.preventDefault();
    let c = event.clipboardData?.getData("text");
    if (c?.length == 6) {
      this.code.controls["c_1"].setValue(c[0]);
      this.code.controls["c_2"].setValue(c[1]);
      this.code.controls["c_3"].setValue(c[2]);
      this.code.controls["c_4"].setValue(c[3]);
      this.code.controls["c_5"].setValue(c[4]);
      this.code.controls["c_6"].setValue(c[5]);
      this.onCodeSend();
    }
  }

  allCodeDone(): boolean {
    return (
      this.code.controls["c_1"].value &&
      this.code.controls["c_2"].value &&
      this.code.controls["c_3"].value &&
      this.code.controls["c_4"].value &&
      this.code.controls["c_5"].value &&
      this.code.controls["c_6"].value
    );
  }

  clearCode(): void {
    this.code.controls["c_1"].setValue("");
    this.code.controls["c_2"].setValue("");
    this.code.controls["c_3"].setValue("");
    this.code.controls["c_4"].setValue("");
    this.code.controls["c_5"].setValue("");
    this.code.controls["c_6"].setValue("");
  }

  onCodeSend() {
    var code =
      this.code.controls["c_1"].value +
      this.code.controls["c_2"].value +
      this.code.controls["c_3"].value +
      this.code.controls["c_4"].value +
      this.code.controls["c_5"].value +
      this.code.controls["c_6"].value;
    this.apiService.UserActivate(code).subscribe({
      next: (response) => {
        if (!response.result) {
          this.clearCode();
          return;
        }
        this.userService.refreshData().subscribe({
          next: (response) => {
            this.success = true;
            setTimeout(() => {
              this.router.navigate([""]);
            }, 2000);
          },
        });
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  resendClicked() {
    this.apiService.resendActivationEmail().subscribe({
      next: (response) => {
        if (response.result) {
          this.translate.get("activation.resent").subscribe((res) => {
            this.toastService.showAlert(res);
          });
        }
      },
    });
  }
}
