import { Component, OnInit } from "@angular/core";
import { ApiService } from "../../Core/Services/Api/api.service";
import { CommonModule } from "@angular/common";
import { DomSanitizer } from "@angular/platform-browser";
import { Router } from "@angular/router";

@Component({
  selector: "app-blogs",
  standalone: true,
  imports: [CommonModule],
  templateUrl: "./blogs.component.html",
  styleUrl: "./blogs.component.scss",
})
export class BlogsComponent implements OnInit {
  constructor(
    private apiService: ApiService,
    public sanitizer: DomSanitizer,
    private router: Router
  ) {}

  blogs: any[] = [];

  ngOnInit(): void {
    this.apiService.getBlogs().subscribe((response) => {
      this.blogs = response.data;
      console.log(this.blogs);
    });
  }

  onBlogClick(blog: any) {
    this.router.navigate(["/blogs", blog.url]);
  }
}
