export function loadModule(_a) {
  var src = _a.src,
    id = _a.id,
    name = _a.name;
  var script = document.createElement('script');
  script.id = id;
  script.src = src;
  script.async = true;
  document.head.appendChild(script);
  return new Promise(function (resolve, reject) {
    function handleError(reason) {
      document.head.removeChild(script);
      reject(new Error("'" + name + "' failed to load: " + reason));
    }
    function handleLoad() {
      resolve(script);
    }
    script.onload = handleLoad;
    script.onerror = function () {
      return handleError('Network error encountered');
    };
  });
}
export function getVersionedUrl(url, version) {
  var queryParams = version ? new URLSearchParams({
    version: version
  }).toString() : '';
  return "" + url + (queryParams ? "?" + queryParams : '');
}