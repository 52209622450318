import { Component } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { ApiService } from "../../../../Core/Services/Api/api.service";
import { CommonModule } from "@angular/common";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "app-password",
  standalone: true,
  imports: [FormsModule, CommonModule, TranslateModule],
  templateUrl: "./password.component.html",
  styleUrl: "./password.component.scss",
})
export class PasswordComponent {
  constructor(
    private apiService: ApiService,
    private activeModal: NgbActiveModal
  ) {}
  password: string = "";
  invalid_password = false;
  onPasswordSend() {
    this.apiService.XoaLogin(this.password).subscribe((response) => {
      if (!response.result) {
        this.invalid_password = true;
      } else {
        this.activeModal.close(response.data);
      }
    });
  }
}
