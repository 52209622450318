<div class="container" style="overflow-x: scroll">
  <table class="table my-5">
    <thead>
      <tr>
        <th>{{ "invoice.docnumber" | translate }}</th>
        <th>{{ "invoice.ordernumber" | translate }}</th>
        <th>{{ "invoice.invoicetime" | translate }}</th>
        <th>{{ "invoice.netto" | translate }}</th>
        <th>{{ "invoice.afa" | translate }}</th>
        <th>{{ "invoice.brutto" | translate }}</th>
        <th>{{ "invoice.billingo_title" | translate }}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let invoice of invoices">
        <td>{{ invoice.docnumber }}</td>
        <td>{{ invoice.orderdocnumber }}</td>
        <td>{{ invoice.invoicetime }} (CEST)</td>
        <td>
          {{
            currencyService.printCurrencyById(invoice.netto, invoice.currencyid)
          }}
        </td>
        <td>
          {{
            currencyService.printCurrencyById(invoice.afa, invoice.currencyid)
          }}
        </td>
        <td>
          {{
            currencyService.printCurrencyById(
              invoice.brutto,
              invoice.currencyid
            )
          }}
        </td>
        <td>
          <a href="{{ invoice.billingourl }}/download">{{
            "invoice.billingo_link" | translate
          }}</a>
        </td>
      </tr>
    </tbody>
  </table>
</div>
