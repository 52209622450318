import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, of } from "rxjs";
import { Response } from "../../models/Response";
import { Product, ProductCategory } from "../../models/Product";
import { CustomerType } from "../../models/Master";
import { CartItem } from "../../models/Cart";
import { PlaceType } from "../../models/PlaceType";
import { Country } from "../../models/Country";
import { FormGroup } from "@angular/forms";
import { Language } from "../../models/Language";
import { VPS, VPSInfo } from "../../models/VPS";
import { OrderData } from "../../models/Order";
import { environment } from "../../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  constructor(private http: HttpClient) {}

  getProductTree() {
    environment.apiUrl;
    return this.http
      .get<Response<ProductCategory[]>>(environment.apiUrl + "producttree/get")
      .pipe(map((response) => response.data));
  }

  getProductData(id: number) {
    return this.http
      .get<Response<Product[]>>(environment.apiUrl + "products/get/" + id)
      .pipe(map((response) => response.data));
  }

  UserRegister(userForm: FormGroup, google: string = "", facebook: any = null) {
    let fd = new FormData();

    if (userForm.controls["name"] && userForm.controls["name"].value) {
      fd.append("name", userForm.controls["name"].value);
    }
    if (userForm.controls["email"] && userForm.controls["email"].value) {
      fd.append("email", userForm.controls["email"].value);
    }
    if (userForm.controls["typeid"] && userForm.controls["typeid"].value) {
      fd.append("typeid", userForm.controls["typeid"].value);
    }
    if (userForm.controls["cname"] && userForm.controls["cname"].value) {
      fd.append("cname", userForm.controls["cname"].value);
    }
    if (
      userForm.controls["taxnumber"] &&
      userForm.controls["taxnumber"].value
    ) {
      fd.append("taxnumber", userForm.controls["taxnumber"].value);
    }
    if (
      userForm.controls["shortname"] &&
      userForm.controls["shortname"].value
    ) {
      fd.append("shortname", userForm.controls["shortname"].value);
    }
    if (userForm.controls["position"] && userForm.controls["position"].value) {
      fd.append("position", userForm.controls["position"].value);
    }
    if (userForm.controls["password"] && userForm.controls["password"].value) {
      fd.append("password", userForm.controls["password"].value);
    }

    if (google) {
      fd.append("google", google);
    }
    if (facebook) {
      fd.append("facebook", facebook.token);
    }
    return this.http.post<Response<any>>(
      environment.apiUrl + "register/UC/",
      fd
    );
  }

  UserActivate(code: string) {
    let fd = new FormData();
    fd.append("activationcode", code);
    return this.http.post<Response<string>>(
      environment.apiUrl + "emailactivationcode/POST",
      fd
    );
  }

  UserActivateLink(code: string) {
    let fd = new FormData();
    fd.append("activationcode", code);
    return this.http.post<Response<string>>(
      environment.apiUrl + "emailactivationlink/POST",
      fd
    );
  }

  UserLogin(email: string, password: string) {
    let fd = new FormData();
    fd.append("login", email);
    fd.append("password", password);
    return this.http.post<Response<any>>(environment.apiUrl + "login/UC/", fd);
  }

  UserLogout() {
    return this.http.get(environment.apiUrl + "logout/UC/");
  }

  googleAuth(code: string) {
    let fd = new FormData();
    fd.append("token", code);
    return this.http.post<Response<any>>(
      environment.apiUrl + "auth/google",
      fd
    );
  }

  facebookAuth(code: string) {
    let fd = new FormData();
    fd.append("token", code);
    return this.http.post<Response<any>>(
      environment.apiUrl + "auth/facebook",
      fd
    );
  }

  UserChangePassword(oldpassword: string, newpassword: string) {
    let fd = new FormData();
    fd.append("oldpassword", oldpassword);
    fd.append("newpassword", newpassword);
    return this.http.post(environment.apiUrl + "changepassword/UC/", fd);
  }

  GetUser() {
    return this.http.get<Response<any>>(environment.apiUrl + "userdata/GET");
  }

  GetCustomer() {
    return this.http.get<Response<any[]>>(environment.apiUrl + "customer/GET");
  }

  AddCustomerAddress(customerId: string, billingForm: FormGroup) {
    let fd = new FormData();
    if (
      billingForm.controls["country"] &&
      billingForm.controls["country"].value
    ) {
      fd.append("countryid", billingForm.controls["country"].value);
    }
    if (
      billingForm.controls["zipcode"] &&
      billingForm.controls["zipcode"].value
    ) {
      fd.append("irsz", billingForm.controls["zipcode"].value);
    }
    if (billingForm.controls["city"] && billingForm.controls["city"].value) {
      fd.append("city", billingForm.controls["city"].value);
    }
    if (
      billingForm.controls["address"] &&
      billingForm.controls["address"].value
    ) {
      fd.append("street", billingForm.controls["address"].value);
    }
    if (
      billingForm.controls["housenumber"] &&
      billingForm.controls["housenumber"].value
    ) {
      fd.append("housenr", billingForm.controls["housenumber"].value);
    }
    if (
      billingForm.controls["placetype"] &&
      billingForm.controls["placetype"].value
    ) {
      fd.append("placetypeid", billingForm.controls["placetype"].value);
    }
    fd.append("main", "1");
    fd.append("customerid", customerId);
    fd.append("typeid", "2");
    return this.http.post<Response<any>>(
      environment.apiUrl + "customeraddress/ADD/",
      fd
    );
  }

  GetCustomerAddress() {
    return this.http.get<Response<any>>(
      environment.apiUrl + "customeraddress/GET"
    );
  }

  deleteCustomerAddress(customerId: number, addressId: number) {
    let fd = new FormData();
    fd.append("customerid", String(customerId));
    fd.append("customeraddressid", String(addressId));
    return this.http.post<Response<any>>(
      environment.apiUrl + "deletecustomeraddress/POST",
      fd
    );
  }

  // TÖRZSADATOK

  getAddressTypes() {
    return this.http.get<Response<any>>(environment.apiUrl + "addresstype/GET");
  }

  getAfa() {
    return this.http.get(environment.apiUrl + "afa/GET/");
  }

  getContactTypes() {
    return this.http.get(environment.apiUrl + "contacttype/GET/");
  }

  getCountries() {
    return this.http.get<Response<Country[]>>(
      environment.apiUrl + "country/GET"
    );
  }

  getCurrencies() {
    return this.http.get<Response<any>>(environment.apiUrl + "currency/GET");
  }

  getCustomerTypes() {
    return this.http.get<Response<CustomerType[]>>(
      environment.apiUrl + "customertype/GET"
    );
  }

  getUnits() {
    return this.http.get(environment.apiUrl + "me/GET");
  }

  getPaymentTypes() {
    return this.http.get(environment.apiUrl + "paymenttype/GET");
  }

  getPlaceTypes() {
    return this.http.get<Response<PlaceType[]>>(
      environment.apiUrl + "placetype/GET"
    );
  }

  getPriceTypes() {
    return this.http.get<Response<any>>(environment.apiUrl + "pricetype/GET/");
  }

  getProductTypes() {
    return this.http.get(environment.apiUrl + "producttype/GET/");
  }

  getTaxAreas() {
    return this.http.get(environment.apiUrl + "taxarea/GET/");
  }

  // Kosár

  priceCalc(cart: CartItem[], pricetypeid: number = 0, cupon: string = "") {
    let items: any[] = [];

    cart.forEach((element) => {
      let extras: any[] = [];
      element.extras.forEach((e) => {
        extras.push({ productid: e.productid, quantity: e.quantity });
      });
      items.push({
        productid: +element.productid,
        quantity: element.quantity,
        extra: extras,
      });
    });
    let post = {
      pricetypeid: pricetypeid,
      currencycode: "EUR",
      items: items,
      kupon: cupon ? cupon : undefined,
    };
    let fd = new FormData();
    fd.append("data", JSON.stringify(post));
    return this.http.post<Response<any>>(
      environment.apiUrl + "calcprice/post",
      fd
    );
  }

  webOrder(cart: CartItem[], post: any, card: any) {
    let items: any[] = [];
    if (card) {
      post.card_type = card.type;
      post.card_brand = card.card_brand;
      post.card_last_four = card.card_last_four;
    }
    cart.forEach((element) => {
      let extras: any[] = [];
      element.extras.forEach((e) => {
        extras.push({ productid: e.productid, quantity: e.quantity });
      });
      items.push({
        productid: +element.productid,
        quantity: element.quantity,
        extra: extras,
      });
    });
    post.items = items;
    let fd = new FormData();
    fd.append("data", JSON.stringify(post));
    return this.http.post<Response<any>>(
      environment.apiUrl + "weborder/post",
      fd
    );
  }

  getWebText(id: string) {
    return this.http.get<Response<any>>(
      environment.apiUrl + "webtext/GET/" + id
    );
  }

  getOrdersData() {
    return this.http.get<Response<OrderData[]>>(
      environment.apiUrl + "ordersdata/GET/"
    );
  }

  getOrders() {
    return this.http.get<Response<any>>(environment.apiUrl + "orders/GET/");
  }

  getOrder(id: number) {
    return this.http.get<Response<any[]>>(
      environment.apiUrl + "orders/GET/" + id
    );
  }

  getOrderDetails() {
    return this.http.get<Response<any>>(
      environment.apiUrl + "orderdetail/GET/"
    );
  }

  getOrderDetail(id: number) {
    return this.http.get<Response<any>>(
      environment.apiUrl + "orderdetail/GET/" + id
    );
  }

  getOrderPayments(id: number) {
    return this.http.get<Response<any>>(
      environment.apiUrl + "orderpayments/GET/" + id
    );
  }

  getInvoices() {
    return this.http.get<Response<any>>(environment.apiUrl + "invoices/GET/");
  }

  getOrderInvoices(id: number) {
    return this.http.get<Response<any>>(
      environment.apiUrl + "invoices/GET/" + id
    );
  }

  checkOrderPayment(id: number) {
    return this.http.get<Response<any>>(
      environment.apiUrl + "checkorderpayment/GET/" + id
    );
  }

  unsubService(id: number) {
    return this.http.get<Response<any>>(
      environment.apiUrl + "unsubvps/GET/" + id
    );
  }

  subService(id: number) {
    return this.http.get<Response<any>>(
      environment.apiUrl + "subvps/GET/" + id
    );
  }
  /*
  checkOrderPayment(id: number) {
    return this.http.get<Response<any>>(
      `https://test-backend.clmaster.cloud:20443/api/ordersubscriptionpayments/GET/${id}`
    );
  }
  */

  getOrderVps(order_id: number) {
    return this.http.get<Response<any>>(
      environment.apiUrl + "vpsorders/GET/" + order_id
    );
  }

  forgotPassword(email: string) {
    let fd = new FormData();
    fd.append("email", email);
    return this.http.post<Response<boolean>>(
      environment.apiUrl + "forgotpassword/POST/",
      fd
    );
  }

  resetPassword(email: string, password: string, secret: string) {
    let fd = new FormData();
    fd.append("email", email);
    fd.append("code", secret);
    fd.append("password", password);
    return this.http.post<Response<boolean>>(
      environment.apiUrl + "changeforgotpassword/POST/",
      fd
    );
  }
  getLanguages() {
    return this.http.get<Response<Language[]>>(
      environment.apiUrl + "lang/GET/"
    );
  }

  changeUserPassword(current_password: string, new_password: string) {
    let fd = new FormData();
    fd.append("oldpassword", current_password);
    fd.append("newpassword", new_password);
    return this.http.post<Response<boolean>>(
      environment.apiUrl + "changepassword/UC/",
      fd
    );
  }

  changeLanguage(langId: number, userId: number | undefined = undefined) {
    let fd = new FormData();
    fd.append("langid", String(langId));
    if (userId) {
      fd.append("userid", String(userId));
    }
    return this.http.post<Response<any>>(
      environment.apiUrl + "setlang/POST/",
      fd
    );
  }

  changeCustomerLanguage(
    langId: number,
    customer: number | undefined = undefined
  ) {
    let fd = new FormData();
    fd.append("langid", String(langId));
    if (customer) {
      fd.append("customerid", String(customer));
    }
    return this.http.post<Response<any>>(
      environment.apiUrl + "setlang/POST/",
      fd
    );
  }

  changeCustomerCurrency(
    currencyid: number,
    customer: number | undefined = undefined,
    user: number | undefined = undefined
  ) {
    let fd = new FormData();
    fd.append("currencyid", String(currencyid));
    if (customer) {
      fd.append("customerid", String(customer));
    }
    if (user) {
      fd.append("userid", String(user));
    }
    return this.http.post<Response<any>>(
      environment.apiUrl + "setcurrency/POST/",
      fd
    );
  }

  //VPS APIS

  getVPSes() {
    return this.http.get<Response<VPS[]>>(environment.apiUrl + "vps/GET/");
  }

  getVPS(vps_id: number) {
    return this.http.get<Response<VPS[]>>(
      environment.apiUrl + "vps/GET/" + vps_id
    );
  }

  getVPSInfo(vps_id: number) {
    return this.http.get<Response<VPSInfo>>(
      environment.apiUrl + "vpsstate/GET/" + vps_id
    );
  }

  startVPS(vps_id: number) {
    return this.http.get<Response<any>>(
      environment.apiUrl + "vpsstart/GET/" + vps_id
    );
  }

  stopVPS(vps_id: number) {
    return this.http.get<Response<any>>(
      environment.apiUrl + "vpsstop/GET/" + vps_id
    );
  }

  forceStopVPS(vps_id: number) {
    return this.http.get<Response<any>>(
      environment.apiUrl + "vpshardstop/GET/" + vps_id
    );
  }

  rebootVPS(vps_id: number) {
    return this.http.get<Response<any>>(
      environment.apiUrl + "vpsreboot/GET/" + vps_id
    );
  }

  forceRebootVPS(vps_id: number) {
    return this.http.get<Response<any>>(
      environment.apiUrl + "vpshardreboot/GET/" + vps_id
    );
  }

  vpsProxy(
    vps_id: number,
    status: string,
    domain: string | undefined = undefined
  ) {
    let fd = new FormData();
    fd.append("vpsid", String(vps_id));
    fd.append("proxy", status);
    if (domain) {
      fd.append("domain", domain);
    }
    return this.http.post<Response<any>>(
      environment.apiUrl + "vpsproxy/POST/",
      fd
    );
  }

  // SUPPORT

  getSupportMessageTypes() {
    return this.http.get<Response<any>>(environment.apiUrl + "messagetype/GET");
  }

  getSupportMessageSubType(sub_type: string) {
    return this.http.get<Response<any>>(
      environment.apiUrl + `messagetype/GET/${sub_type}`
    );
  }

  sendSupportMessage(
    type: { id: number; name: string; type: string },
    message: string,
    email: string,
    name: string
  ) {
    let fd = new FormData();
    fd.append("id", String(type.id));
    fd.append("name", type.name);
    fd.append("type", type.type);
    fd.append("message", message);
    fd.append("useremail", email);
    fd.append("username", name);
    return this.http.post<Response<any>>(
      environment.apiUrl + "sendmessage/POST",
      fd
    );
  }

  sendMessage(name: string, email: string, subject: string, message: string) {
    let fd = new FormData();
    fd.append("id", "3");
    fd.append("name", subject);
    fd.append("type", "");
    fd.append("message", message);
    fd.append("emailaddress", email);
    fd.append("username", name);
    return this.http.post<Response<any>>(
      environment.apiUrl + "sendmessage/POST",
      fd
    );
  }

  // USER CARDS

  getUserCards() {
    return this.http.get<Response<any>>(environment.apiUrl + "cards/get/");
  }

  createUserCard(customerid: number, customeraddressid: number) {
    let fd = new FormData();
    let post = {
      customerid: customerid,
      customeraddressid: customeraddressid,
    };
    fd.append("data", JSON.stringify(post));
    return this.http.post<Response<any>>(
      environment.apiUrl + "registercard/POST",
      fd
    );
  }

  deleteUserCard(
    customerid: number,
    type: string,
    brand: string,
    last_four: string
  ) {
    let fd = new FormData();
    fd.append("customerid", String(customerid));
    fd.append("type", type);
    fd.append("card_brand", brand);
    fd.append("card_last_four", last_four);
    return this.http.post<Response<any>>(
      environment.apiUrl + "removecard/POST",
      fd
    );
  }

  payOrderWithCard(
    customerid: number,
    orderid: number,
    type: string,
    brand: string,
    last_four: string
  ) {
    let post = {
      orderid: orderid,
      customerid: customerid,
      card_last_four: last_four,
      card_brand: brand,
      card_type: type,
    };
    let fd = new FormData();
    fd.append("data", JSON.stringify(post));
    return this.http.post<Response<any>>(
      environment.apiUrl + "payorder/POST",
      fd
    );
  }

  // SUBSCRIPTIONS

  getSubs() {
    return this.http
      .get<Response<any[]>>(environment.apiUrl + "vpssubscription/GET")
      .pipe(
        map((response) => {
          return response.data.filter((item) => item.sts === "N");
        })
      );
  }

  getSubscriptions() {
    return this.http.get<Response<any>>(
      environment.apiUrl + "ordersubscriptions/GET"
    );
  }

  getSubscription(id: number) {
    return this.http.get<Response<any>>(
      environment.apiUrl + "ordersubscriptions/GET/" + id
    );
  }

  getSubscriptionDetails(id: number) {
    return this.http.get<Response<any>>(
      environment.apiUrl + "ordersubscriptiondetail/GET/" + id
    );
  }

  getSubscriptionPayments(id: number) {
    return this.http.get<Response<any>>(
      environment.apiUrl + "ordersubscriptionpayments/GET/" + id
    );
  }

  getPriceTypesInProducts(products: number[]) {
    let fd = new FormData();
    fd.append("products", JSON.stringify(products));
    return this.http.post<Response<any>>(
      environment.apiUrl + "pricetypeinallproducts/POST",
      fd
    );
  }

  XoaLogin(password: string) {
    let fd = new FormData();
    fd.append("password", password);
    return this.http.post<Response<any>>(
      environment.apiUrl + "xoatoken/GET",
      fd
    );
  }

  // BLOGS

  getBlogCount() {
    return this.http.get<Response<any>>(environment.apiUrl + "blog/count");
  }

  getBlogs() {
    return this.http.get<Response<any>>(environment.apiUrl + "blog/GET");
  }

  getBlog(url: string) {
    return this.http.get<Response<any>>(environment.apiUrl + `blog/GET/${url}`);
  }

  resendActivationEmail() {
    return this.http.get<Response<any>>(
      environment.apiUrl + "resendactivationcode/GET"
    );
  }
}
